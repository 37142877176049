import React from "react";
import Head from "next/head";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import Link from "next/link";
import { DASHBOARD } from "../src/constants/routes";

const Page404 = () => {
  const { t } = useTranslation(["common"]);
  return (
    <React.Fragment>
      <Head>
        <title>{"404 - Not Found - Plan Well Guide"}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <h1>404 - Not Found</h1>
        <Link passHref href={DASHBOARD}>
          <Button
            size="large"
            style={{ width: 300 }}
            variant="contained"
            color="secondary"
          >
            {t("common:buttons.goBack")}
          </Button>
        </Link>
      </div>
    </React.Fragment>
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      "common",
      "dashboard",
      "footer",
      "header",
    ])),
  },
});

export default Page404;
